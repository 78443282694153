/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import ReactCursorPosition from 'react-cursor-position'
import { ScrollPositionContext } from 'store/ScrollPosition'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  /* for fake light glare */
  /* background: circular-gradient(45deg, #ddd, #fff, #ddd); */
`

const CursorBox = styled.div`
  pointer-events: none;
  z-index: 10000;
  width: 200%;
  height: 200%;
  position: absolute;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  z-index: 10;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.7);
  /* border-radius: 50%; */
  background: radial-gradient(
    circle at 50% 50%,
    transparent 17%,
    rgba(0, 0, 0, 0.5) 18%,
    rgba(0, 0, 0, 0.7) 20%
  );
  overflow: hidden;
`
class CursorSpotlight extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.addEventListener('scroll', this.addDifference)
  }

  render() {
    const {
      children,
      detectedEnvironment: {
        isMouseDetected = false,
        isTouchDetected = false
      } = {},
      elementDimensions: { width = 0, height = 0 } = {},
      isActive = false,
      isPositionOutside = false,
      position: { x = 0, y = 0 } = {}
    } = this.props
    return (
      <>
        {children}
        {isMouseDetected && !isMobile && isActive && (
          <Wrapper className="wrapper">
            <>
              <>
                <ScrollPositionContext.Consumer>
                  {({ scrollSinceMouseMove }) => (
                    <CursorBox
                      style={{
                        left: `calc(${x}px - 100%)`,
                        top: `calc(${y + scrollSinceMouseMove}px - 100%)`
                      }}
                    />
                  )}
                </ScrollPositionContext.Consumer>
              </>
            </>
          </Wrapper>
        )}
      </>
    )
  }
}

export default props => (
  <ReactCursorPosition>
    <CursorSpotlight {...props} />
  </ReactCursorPosition>
)
