import React from 'react'
import { ContentfulContext } from 'store/Contentful'
import styled from 'styled-components'
import withSizes from 'react-sizes'
import { mobile } from 'styles/breakpoints'
import colors from 'styles/colors'
import { isMobile } from 'react-device-detect'

const TRANSITION_SPEED = '500ms'

const BoxShadow = styled.div`
  -webkit-transform-style: preserve-3d;
  @media (max-width: ${`${mobile}px`}) {
    margin: 50% 0;
    width: 100%;
    align-self: center;
  }
  width: 70%;
  margin: 20% 0;
  ${({ index }) =>
    index % 2
      ? `align-self: flex-end;
        margin-left: auto;
  `
      : `align-self: flex-start;
        margin-right: auto;
  `}
`

const ItemWrapper = styled.div`
  perspective: 600px;
  color: ${colors.black};
  /* transition: transform 50ms; */
  z-index: 1;
  cursor: pointer;
  transition: 'transform 300ms';
  position: relative;
  text-align: center;
  flex: 1;
  img {
    transition: opacity ${TRANSITION_SPEED};
  }
  ${({
    // eslint-disable-next-line no-shadow
    isMobile
  }) =>
    !isMobile &&
    `
      &:hover {
        img {
          opacity: 0;
        }
        .hover-reveal {
          opacity: 1;
        }
      }
  `}
`

const HoverReveal = styled.div`
  font-size: 25px;
  @media (max-width: ${`${mobile}px`}) {
    font-size: 18px;
    h2 {
      font-size: 18px;
    }
  }
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  transition: opacity ${TRANSITION_SPEED};
  opacity: 0;
  border: 1px solid ${colors.black};
  div {
    max-width: 50%;
  }
`
class VideoItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      middleOfElement: null,
      fadeOut: false
    }
  }

  componentDidMount() {
    setTimeout(() => this.calculateElementPosition(), 300) // timeout prevents error getting clientHeight before images load
    window.addEventListener('resize', this.calculateElementPosition)
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.calculateElementPosition)
  }

  calculateElementPosition = () => {
    const {
      version,
      video: {
        sys: { id }
      }
    } = this.props
    const element = document.getElementById(id + version)
    if (!element) return
    const { offsetTop, clientHeight } = element
    const middleOfElement = offsetTop + clientHeight / 2
    this.setState({ middleOfElement })
  }

  render() {
    const {
      debug,
      clone,
      version,
      scrollPosition,
      index,
      middleOfWindow,
      video: {
        sys: { id },
        fields: {
          internalTitle,
          displayTitle,
          artistName,
          credits,
          description,
          vimeoUrl,
          previewImage,
          allImages
        }
      }
    } = this.props
    const { middleOfElement, fadeOut } = this.state
    const rawDistanceFromCenter =
      scrollPosition + middleOfWindow - middleOfElement
    const distanceFromCenter = Math.abs(rawDistanceFromCenter)
    let className = 'videoItem'
    if (clone) className += ' clone'
    if (fadeOut) className += ' fadeOut'
    return (
      <ContentfulContext.Consumer>
        {({ selectItem }) => (
          <BoxShadow
            className={className}
            index={index}
            background={previewImage.fields.url}
          >
            <ItemWrapper
              isMobile={isMobile}
              className={clone ? 'clone video-inner' : 'video-inner'}
              distanceFromCenter={distanceFromCenter}
              rawDistanceFromCenter={rawDistanceFromCenter}
              id={id + version}
              data-title={displayTitle}
              data-artist={artistName}
              data-distance={`${displayTitle + version}_distance`}
              index={index}
              onClick={() => selectItem({ id })}
            >
              <div
                style={{ perspective: 600 }}
                className="main-image-container"
              >
                {debug && distanceFromCenter}
                <img alt={displayTitle} src={previewImage.fields.file.url} />
                <HoverReveal className="hover-reveal">
                  <div>{artistName && artistName.toUpperCase()}</div>
                  <h2>{displayTitle}</h2>
                </HoverReveal>
              </div>
            </ItemWrapper>
            <img
              className="blur"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: -1,
                transform: 'translateZ(-100px)',
                filter: 'blur(12px)'
              }}
              alt={displayTitle}
              src={previewImage.fields.file.url}
            />
          </BoxShadow>
        )}
      </ContentfulContext.Consumer>
    )
  }
}
const mapSizesToProps = ({ height }) => ({
  middleOfWindow: height / 2
})

export default withSizes(mapSizesToProps)(VideoItem)
