import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

const transitionTiming = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const EntranceWrap = styled.div`
  > * {
    transition: transform ${({ transitionSpeed }) => transitionSpeed}s
        ${transitionTiming},
      opacity ${({ transitionSpeed }) => transitionSpeed}s ${transitionTiming};
    ${({ 'data-in-view': inView, transform, rotate }) =>
      inView
        ? `
      transform: none;
      ${rotate && `rotate3d: ${rotate}`}
			opacity: 1;
		`
        : `
			transform: ${transform || 'translate3d(0, 40px, 0)'};
			opacity: 0;
		`}

    ${({ transitionDelay, firstTransitionDelay, multiplier, children }) =>
      Array.isArray(children)
        ? `
			${children &&
        children.map((item, index) => {
          if (index === 0) {
            return `
          &:nth-child(${index + 1}) {
            transition-delay: ${firstTransitionDelay * multiplier}s;
          }`
          }
          return `
          &:nth-child(${index + 1}) {
            transition-delay: ${firstTransitionDelay * multiplier +
              transitionDelay * (index * multiplier)}s;
          }
        `
        })}
		`
        : ''}
  }
`

const ScrollEntrance = ({
  children,
  className,
  transform,
  rotate,
  transitionSpeed = 0.8,
  transitionDelay = 0.2,
  firstTransitionDelay = 0.4,
  multiplier = 1
}) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <EntranceWrap
      ref={ref}
      data-in-view={inView}
      className={className}
      transform={transform}
      rotate={rotate}
      transitionSpeed={transitionSpeed}
      transitionDelay={transitionDelay}
      firstTransitionDelay={firstTransitionDelay}
      multiplier={multiplier}
    >
      {children}
    </EntranceWrap>
  )
}

export default ScrollEntrance
