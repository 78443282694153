import React from 'react'
import styled from 'styled-components'

const transitionTiming = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const EntranceWrap = styled.div`
  > * {
    transition: transform ${({ transitionSpeed }) =>
      transitionSpeed}s ${transitionTiming},
      opacity ${({ transitionSpeed }) => transitionSpeed}s ${transitionTiming};
    ${({ open, transform }) =>
      open
        ? `opacity: 1; transform: none;`
        : `opacity: 0; transform: ${transform || 'translate3d(0, 40px, 0)'};`}
    ${({ fadingOut, reverseTransform }) =>
      fadingOut &&
      `opacity: 0; transform: ${reverseTransform ||
        'translate3d(0, -40px, 0)'};`}
    ${({ transitionDelay, firstTransitionDelay, multiplier, children }) =>
      Array.isArray(children)
        ? `
			${children &&
        children.map((item, index) => {
          if (index === 0) {
            return `
          &:nth-child(${index + 1}) {
            transition-delay: ${firstTransitionDelay * multiplier}s;
          }`
          }
          return `
          &:nth-child(${index + 1}) {
            transition-delay: ${firstTransitionDelay * multiplier +
              transitionDelay * (index * multiplier)}s;
          }
        `
        })}
		`
        : ''}
  }
`

const ScrollIn = ({
  children,
  transform,
  transitionSpeed = 0.8,
  transitionDelay = 0.8,
  firstTransitionDelay = 0.8,
  multiplier = 1,
  open,
  fadingIn,
  fadingOut,
  reverseTransform
}) => {
  return (
    <EntranceWrap
      transform={transform}
      reverseTransform={reverseTransform}
      transitionSpeed={transitionSpeed}
      transitionDelay={transitionDelay}
      firstTransitionDelay={firstTransitionDelay}
      multiplier={multiplier}
      open={open}
      fadingIn={fadingIn}
      fadingOut={fadingOut}
    >
      {children}
    </EntranceWrap>
  )
}

export default ScrollIn
