import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { isMobile } from 'react-device-detect'
import { mobile } from 'styles/breakpoints'
import { ContentfulContext } from 'store/Contentful'
import ScrollEntrance from 'components/ScrollEntrance'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: opacity 300ms;
  &.fadeOut {
    opacity: 0;
  }
`

const CloseBlock = styled.aside`
  position: fixed;
  top: 25px;
  left: 35px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  cursor: pointer;

  &:hover {
    transform: scale(1.1, 1);
  }
  h4 {
    font-size: 18px;
  }
`

const Column = styled.div`
  margin: 10% 12%;
  @media (max-width: ${`${mobile}px`}) {
    margin: 80px 5%;
  }
  line-height: 1.5;
  font-size: 14px;
  font-family: 'Chapter';
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Right = styled.div`
  text-align: right;
  @media (max-width: ${`${mobile}px`}) {
    text-align: center;
  }
`

const RowMobileCollapse = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 5%;
  justify-content: center;
  > * {
    flex: 1;
  }
  @media (max-width: ${`${mobile}px`}) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`
const RichText = styled.div`
  text-align: left;
  @media (max-width: ${`${mobile}px`}) {
    text-align: center;
  }
`

const Credit = styled.div`
  margin: 5px 0;
`

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
`

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: 10%;
  width: 100%;
  height: 0;
`

const Image = styled.img`
  margin: 10% 0;
  width: 58%;
  ${({ randomStyle }) => randomStyle}
  @media (max-width: ${`${mobile}px`}) {
    width: 70%;
  }
`

const InlineBlock = styled.div`
  display: inline-block;
`

function* shuffleGenerator(array) {
  let i = array.length

  // eslint-disable-next-line no-cond-assign
  while ((i -= 1)) {
    yield array.splice(Math.floor(Math.random() * (i + 1)), 1)[0]
  }
}

class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fadeOut: false
    }
  }

  componentDidMount() {
    this.timeout0 = setTimeout(() => this.reCalc(), 300)
    const entireBody = document.getElementById('entire-body')
    if (entireBody) {
      window.addEventListener('scroll', this.scrollUpdate)
      window.addEventListener('resize', this.reCalc)
    }

    const {
      selectedItem: { /* sys, */ fields }
    } = this.props
    const randomStyles = []
    if (fields.allImages) {
      let gen = shuffleGenerator([2, 4, 6, 8])
      fields.allImages.forEach(() => {
        const { value } = gen.next()
        if (value === undefined) gen = shuffleGenerator([0, 2, 4, 6, 8, 10, 12])
        const pixels = Math.floor(value)
        const side = Math.round(Math.random()) ? 'left' : 'right'
        const style = `
          margin-${side}: ${pixels}vw;
          float: ${side};
        `
        randomStyles.push(style)
      })
      this.setState({ randomStyles })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollUpdate)
    window.removeEventListener('resize', this.reCalc)
  }

  getScrollPos = () => window.scrollY

  setScrollPos = pos => {
    const column = document.getElementById('entire-body')
    if (column) window.scrollTo(0, pos - 1)
  }

  reCalc = () => {
    window.requestAnimationFrame(() => {
      const scrollPos = this.getScrollPos()
      if (!isMobile) {
        const secondColumnTop =
          document.getElementById('second-top') &&
          document.getElementById('second-top').offsetTop
        const tooShortForDouble =
          secondColumnTop <= (window.height || window.innerHeight)
        if (secondColumnTop)
          this.setState({ secondColumnTop, tooShortForDouble })
      }

      if (scrollPos <= 0) {
        this.setScrollPos(
          document.getElementById('first-top') &&
            document.getElementById('first-top').offsetTop
        )
      }
    })
  }

  scrollUpdate = () => {
    window.requestAnimationFrame(() => {
      if (!this.state.disableScroll && !this.state.tooShortForDouble) {
        const scrollPos = this.getScrollPos()
        if (!isMobile && scrollPos >= this.state.secondColumnTop) {
          // Scroll to the top when you’ve reached the bottom
          this.setScrollPos(
            document.getElementById('first-top') &&
              document.getElementById('first-top').offsetTop
          ) // Scroll down 1 pixel to allow upwards scrolling
          this.setState({ disableScroll: true })
        } else if (!isMobile && scrollPos <= 0) {
          // Scroll to the bottom when you reach the top
          this.setScrollPos(this.state.secondColumnTop)
          this.setState({ disableScroll: true })
        }
      } else if (!this.state.tooShortForDouble) {
        // Disable scroll-jumping for a short time to avoid flickering
        this.timeout1 = setTimeout(() => {
          this.setState({ disableScroll: false })
        }, 40)
      }
    })
  }

  handleClick = selectItem => {
    this.setState({ fadeOut: true })
    if (this.timeout0) clearTimeout(this.timeout0)
    if (this.timeout1) clearTimeout(this.timeout1)
    this.timeout2 = setTimeout(() => selectItem({ id: null }), 300)
  }

  render() {
    const {
      selectedItem: { /* sys, */ fields }
    } = this.props
    const { fadeOut, randomStyles, tooShortForDouble } = this.state
    const description = documentToReactComponents(fields.description)
    return (
      <Wrapper id="entire-body" className={fadeOut ? 'fadeOut' : ''}>
        <ContentfulContext.Consumer>
          {({ selectItem }) => (
            <>
              <CloseBlock onClick={() => this.handleClick(selectItem)}>
                <h4>close</h4>
              </CloseBlock>
              <ScrollEntrance firstTransitionDelay={0}>
                <InlineBlock id="first-top">
                  <Column>
                    <RowMobileCollapse>
                      <Right style={{ paddingRight: 12 }}>
                        <ScrollEntrance>
                          <h2>
                            {fields.displayTitle &&
                              fields.displayTitle.toUpperCase()}
                          </h2>
                          <h2>{fields.artistName}</h2>
                          {fields.credits &&
                            fields.credits.map(x => (
                              <Credit key={x}>{x}</Credit>
                            ))}
                        </ScrollEntrance>
                      </Right>
                      <RichText style={{ paddingLeft: 12 }}>
                        <ScrollEntrance firstTransitionDelay={0.2 * 4}>
                          {description}
                        </ScrollEntrance>
                      </RichText>
                    </RowMobileCollapse>
                    <ScrollEntrance firstTransitionDelay={0.2 * 5}>
                      {fields.vimeoUrl && (
                        <PlayerWrapper>
                          <ReactPlayerStyled
                            width="100%"
                            height="100%"
                            controls
                            url={fields.vimeoUrl}
                          />
                        </PlayerWrapper>
                      )}
                      <div>
                        {fields.allImages &&
                          fields.allImages.map(
                            (
                              {
                                fields: {
                                  title,
                                  file: { url }
                                }
                              },
                              index
                            ) => (
                              <Image
                                key={url}
                                randomStyle={
                                  randomStyles && randomStyles[index]
                                }
                                alt={title}
                                src={url}
                              />
                            )
                          )}
                      </div>
                    </ScrollEntrance>
                  </Column>
                </InlineBlock>
                {!tooShortForDouble ||
                  (isMobile && (
                    <InlineBlock id="second-top">
                      <Column>
                        <RowMobileCollapse>
                          <Right style={{ paddingRight: 12 }}>
                            <div>
                              <h2>
                                {fields.displayTitle &&
                                  fields.displayTitle.toUpperCase()}
                              </h2>
                              <h2>{fields.artistName}</h2>
                              {fields.credits &&
                                fields.credits.map(x => (
                                  <Credit key={x}>{x}</Credit>
                                ))}
                            </div>
                          </Right>
                          <RichText style={{ paddingLeft: 12 }}>
                            <div>{description}</div>
                          </RichText>
                        </RowMobileCollapse>
                        <div>
                          <PlayerWrapper>
                            <ReactPlayerStyled
                              width="100%"
                              height="100%"
                              controls
                              url={fields.vimeoUrl}
                            />
                          </PlayerWrapper>
                          <div>
                            {fields.allImages &&
                              fields.allImages.map(
                                (
                                  {
                                    fields: {
                                      title,
                                      file: { url }
                                    }
                                  },
                                  index
                                ) => (
                                  <Image
                                    key={`${url}-2`}
                                    randomStyle={
                                      randomStyles && randomStyles[index]
                                    }
                                    alt={title}
                                    src={url}
                                  />
                                )
                              )}
                          </div>
                        </div>
                      </Column>
                    </InlineBlock>
                  ))}
              </ScrollEntrance>
            </>
          )}
        </ContentfulContext.Consumer>
      </Wrapper>
    )
  }
}

export default Item
