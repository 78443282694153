import React from 'react'
import CursorSpotlight from 'components/CursorSpotlight'
import ItemsList from 'components/ItemsList'
import Item from 'components/Item'
import ContentfulProvider, { ContentfulContext } from 'store/Contentful'
import ScrollPositionProvider from 'store/ScrollPosition'
import styled from 'styled-components'
import SiteTitle from 'components/SiteTitle'

const Fade = styled.div`
  transition: opacity 300ms;
  &.fadeOut,
  &.fadeIn {
    opacity: 0;
  }
`

export default () => (
  <ScrollPositionProvider>
    <ContentfulProvider>
      <ContentfulContext.Consumer>
        {({ selectedItem, fadeOut, fadeIn, items }) => {
          let className = ''
          if (fadeOut) className += 'fadeOut'
          if (fadeIn) className += 'fadeIn'
          if (!selectedItem && items) {
            return (
              <Fade className={className}>
                <SiteTitle />
                <ItemsList />
              </Fade>
            )
          }
          if (selectedItem && items) {
            return (
              <Fade className={className}>
                <Item selectedItem={selectedItem} />
              </Fade>
            )
          }
          return ''
        }}
      </ContentfulContext.Consumer>
    </ContentfulProvider>
  </ScrollPositionProvider>
)
