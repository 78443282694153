import React from 'react'
import styled from 'styled-components'
import ScrollEntrance from 'components/ScrollEntrance'

const Wrapper = styled.div`
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 0;

  .background-title-enter {
    opacity: 0;
  }
  .background-title-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .background-title-exit {
    opacity: 1;
  }
  .background-title-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`

const Title = styled.div`
  font-family: 'Digestive';
  font-size: calc(45px + (200 - 45) * ((100vw - 300px) / (1600 - 300)));
  text-transform: uppercase;
`

const SiteTitle = () => (
  <Wrapper>
    <ScrollEntrance transform="translate3d(0, 400px, 0)">
      <Title>Erin Grant</Title>
    </ScrollEntrance>
  </Wrapper>
)

export default SiteTitle
