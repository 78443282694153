import React from 'react'
import ReactCursorPosition from 'react-cursor-position'

const initialState = {
  scrollPosition: null,
  diff: 0,
  ticking: false,
  scrollSinceMouseMove: 0
}

export const ScrollPositionContext = React.createContext(initialState)

class ScrollPositionProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.timer = null
  }

  clearDiff = () => {
    this.setState({ diff: 0 })
  }

  latestMoveMouse = () => this.setState({ scrollSinceMouseMove: 0 })

  render() {
    const { children } = this.props
    return (
      <ReactCursorPosition onPositionChanged={this.latestMoveMouse}>
        <ScrollPositionContext.Provider
          value={{
            ...this.state
          }}
        >
          {children}
        </ScrollPositionContext.Provider>
      </ReactCursorPosition>
    )
  }
}

export default ScrollPositionProvider
