import React from 'react'
import { createClient } from 'contentful'

const initialState = {
  items: null,
  selectItem: () => {},
  selectedItem: null
}

export const ContentfulContext = React.createContext(initialState)

class ContentfulProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_API_TOKEN
    })
    client
      .getEntries()
      .then(({ items }) => this.setState({ items }))
      .catch(console.error)
    // this.setState({ client })
  }

  selectItem = ({ id }) => {
    const { items } = this.state
    if (id === null) {
      this.setState({ fadeOut: true })
      setTimeout(
        () =>
          this.setState({ fadeOut: false, fadeIn: true, selectedItem: null }),
        300
      )
      setTimeout(() => this.setState({ fadeIn: false }), 600)
    } else {
      const selectedItem = items.find(x => x.sys.id === id)
      this.setState({ fadeOut: true })
      setTimeout(() => {
        window.scroll(0, 0)
        this.setState({ selectedItem, fadeOut: false, fadeIn: true })
      }, 300)
      setTimeout(() => this.setState({ fadeIn: false }), 600)
    }
  }

  render() {
    const { children } = this.props
    return (
      <ContentfulContext.Provider
        value={{
          ...this.state,
          selectItem: this.selectItem
        }}
      >
        {children}
      </ContentfulContext.Provider>
    )
  }
}

export default ContentfulProvider
