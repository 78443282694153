/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { ContentfulContext } from 'store/Contentful'
import { ScrollPositionContext } from 'store/ScrollPosition'
import VideoItem from 'components/VideoItem'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { mobile, tablet } from 'styles/breakpoints'
import ScrollEntrance from 'components/ScrollEntrance'
import ScrollIn from 'components/ScrollIn'
import colors from 'styles/colors'
import { IoLogoVimeo, IoLogoInstagram } from 'react-icons/io'

const ScrollEntranceStyled = styled(ScrollEntrance)`
  > div:last-of-type {
    margin-bottom: 0;
  }
`

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoBlock = styled.aside`
  position: fixed;
  top: 25px;
  right: 35px;
  width: 50px;
  height: 50px;
  max-width: 100%;
  /* display:inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 22;
  cursor: pointer;
  font-size: 25px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid ${colors.black};
    border-radius: 50%;
    transition: transform 0.25s;
  }
  &:hover > div {
    ${isMobile ? '' : `transform: scaleX(-1);`}
  }
`

const Bio = styled.div`
  &.hidden {
    height: 0;
    width: 0;
    top: 33px;
    right: 50px;
    visibility: hidden;
    border-radius: 30%;
    border-color: rgba(0, 0, 0, 1);
  }
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  top: 0;
  right: 0;
  transition: width 1.5s ease-in-out, height 1.5s ease-in-out,
    visibility 1.5s ease-in-out, border-radius 1.5s ease-in-out,
    border-color 1.5s ease-in-out, top 1.5s ease-in-out, right 1.5s ease-in-out;
  background: ${colors.white};
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 21;
  font-family: 'Ogg-Roman';
  font-size: calc(3vw + 20px);
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  a {
    font-family: 'Ogg-Roman';
    font-size: calc(3vw + 20px);
    color: ${colors.black};
    text-decoration: none;
  }
  h5 {
    margin: 0.3em;
  }
`

const ClosestSectionTitle = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 5;
  bottom: 0;
  left: 0;
  padding: 30px;
  visibility: hidden;
  @media (max-width: ${`${mobile}px`}) {
    visibility: visible;
  }
`

class ItemsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bio: false,
      fadingIn: false,
      fadingOut: false,
      loop: null,
      sections: null,
      second: null,
      closestSectionTitle: null
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log('github.com/dillon')
    const loop = document.getElementById('loop')
    const sections = document.querySelectorAll('div.videoItem')
    const second = document.getElementById('second')
    this.setState({ loop, sections, second }, () => {
      this.reCalc()
      this.setState({
        timeout1: setTimeout(() => {
          if (this.getScrollPos() === 0) this.setScrollPos(1)
        }, 100),
        timeout2: setTimeout(() => this.reCalc(), 300),
        timeout3: setTimeout(() => this.reCalc(), 800)
      })
      if (!loop || !sections) return
      loop.addEventListener(
        'scroll',
        () => {
          window.requestAnimationFrame(() => {
            if (!isMobile) this.scrollUpdate()
            this.addMovement()
          })
        },
        true
      )
      window.addEventListener('resize', () => {
        this.reCalc()
        this.addMovement()
      })
    })
  }

  componentWillUnmount() {
    const {
      loop,
      timeout1,
      timeout2,
      timeout3,
      timeout4,
      timeout5
    } = this.state
    const timeouts = [timeout1, timeout2, timeout3, timeout4, timeout5]
    timeouts.forEach(x => {
      if (x) clearTimeout(x)
    })
    loop.removeEventListener('scroll', () => {
      window.requestAnimationFrame(() => {
        if (!isMobile) this.scrollUpdate()
        this.addMovement()
      })
    })
    window.removeEventListener('resize', () => {
      this.reCalc()
      this.addMovement()
    })
  }

  reCalc = () => {
    const { second } = this.state
    const secondListTop = second && second.offsetTop
    this.setState({ secondListTop })
  }

  getScrollPos = () => {
    const { loop } = this.state
    return (loop.offsetTop || loop.scrollTop) - (loop.clientTop || 0) || 0
  }

  setScrollPos = pos => {
    const loop = document.getElementById('loop')
    if (loop) loop.scrollTop = pos
  }

  scrollUpdate = () => {
    const scrollPos = this.getScrollPos()
    if (scrollPos >= this.state.secondListTop) {
      // Scroll to the top when you’ve reached the bottom
      this.setScrollPos(1) // Scroll down 1 pixel to allow upwards scrolling
    } else if (scrollPos <= 0) {
      // Scroll to the bottom when you reach the top
      this.setScrollPos(this.state.secondListTop - 1)
    }
  }

  addMovement = () => {
    let cloneIndex = 0
    const closest = { title: null, artist: null, distance: 999999 }
    const { loop } = this.state
    this.state.sections.forEach((section, index) => {
      const div = section.querySelector('div.video-inner')
      const image = section.querySelector('div.main-image-container')
      const blur = section.querySelector('img.blur')
      const clone = div.classList.contains('clone')
      const { offsetTop } = section
      let distanceToSection =
        loop.scrollTop +
        loop.offsetHeight / 2 -
        (offsetTop + section.offsetHeight / 2)
      if (Math.abs(distanceToSection) < closest.distance) {
        closest.distance = Math.abs(distanceToSection)
        closest.title = div.dataset.title
        closest.artist = div.dataset.artist
      }

      const bottomIsAboveWindow =
        distanceToSection - section.offsetHeight / 2 >= // the section's bottom
        ((window.innerHeight || window.height) * 2) / 2 // room for padding / smoothness

      const topIsBelowWindow =
        distanceToSection + section.offsetHeight / 2 <= // the section's top
        -((window.innerHeight || window.height) * 2) / 2 // room for padding / smoothness

      if (bottomIsAboveWindow || topIsBelowWindow) {
        distanceToSection +=
          (topIsBelowWindow ? 1 : -1) *
          (section.parentElement && section.parentElement.offsetHeight)
        // section.offsetParent.offsetTop +
        // section.offsetTop +
        // (window.height || window.innerHeight)
        // -1,840
      }

      if (clone) {
        cloneIndex += 1
      }

      const rotation = distanceToSection / 100
      const negRotation = (-1 * distanceToSection) / 100
      const skew = (-1 * distanceToSection) / 40
      const smallSkew = (-1 * distanceToSection) / 100
      const negSkew = distanceToSection / 80
      const opacity = 0.5 + distanceToSection / 500
      const opacity3 = 1.6 - distanceToSection / 500
      const negOpacity = (-1 * distanceToSection) / 300
      const top = -1 * (distanceToSection / 20) + 120
      const left = distanceToSection / 20 + 395

      div.style.transform = `rotateX(${rotation}deg) rotateY(${negRotation}deg) rotateZ(${rotation}deg) skewX(${smallSkew}deg)`
      image.style.transform = `rotateX(${rotation}deg) rotateY(${negRotation}deg) rotateZ(${rotation}deg) skewX(${negSkew}deg)`
      blur.style.transform = `rotateX(${rotation}deg) rotateY(${negRotation}deg) rotateZ(${rotation}deg) translateZ(-15px) skewX(${negSkew}deg)`
      // const rotation = distanceToSection / 200
      // const smallSkew = (-1 * distanceToSection) / 100
      // if ((clone ? cloneIndex + 1 : index) % 2 === 0) {
      // div.style.transform = `
      // rotateX(${-1 * rotation}deg)
      // rotateY(${-1 * rotation}deg)
      // rotateZ(${-1 * rotation}deg)
      // skewX(${-1 * smallSkew}deg)`
      // } else {
      // div.style.transform = `
      // rotateX(${rotation}deg)
      // rotateY(${rotation}deg)
      // rotateZ(${rotation}deg)
      // skewX(${smallSkew}deg)`
      // }
    })

    // this.setState({
    //   closestSectionTitle: closest.title,
    //   closestSectionArtist: closest.artist
    // })
  }

  getClonesHeight = () => {
    const clones = document.querySelectorAll('div.clone')
    let clonesHeight = 0
    clones.forEach(({ offsetHeight }) => {
      clonesHeight += offsetHeight
    })
    return clonesHeight
  }

  handleInfoClick = () => {
    if (this.state.fadingIn || this.state.fadingOut) return
    if (this.state.bio) {
      this.setState({
        fadingOut: true,
        timeout4: setTimeout(
          () => this.setState({ bio: false, fadingOut: false }),
          1200
        )
      })
    } else {
      this.setState({
        fadingIn: true,
        timeout5: setTimeout(
          () => this.setState({ bio: true, fadingIn: false }),
          1500
        )
      })
    }
  }

  render() {
    return (
      <>
        <Bio
          className={`bio ${
            this.state.bio || this.state.fadingIn ? '' : 'hidden'
          }`}
        >
          <ScrollIn
            open={this.state.bio}
            fadingIn={this.state.fadingIn}
            fadingOut={this.state.fadingOut}
            firstTransitionDelay={0}
            transitionDelay={0.2}
            transitionSpeed={1}
          >
            <h5 id="name">Erin Grant</h5>
            <h5 id="director">Director</h5>
            <h5>
              <a id="mail" href="mailto:grant.erin1@gmail.com">
                GRANT.ERIN1@gmail.com
              </a>
            </h5>
            <h5 id="number">715.271.8379</h5>
            <div>
              <a
                style={{ padding: '0px 10px' }}
                href="https://vimeo.com/eringrant"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IoLogoVimeo />
              </a>
              <a
                style={{ padding: '0px 10px' }}
                href="https://instagram.com/uwishi_grant/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IoLogoInstagram />
              </a>
            </div>
          </ScrollIn>
        </Bio>

        <InfoBlock onClick={this.handleInfoClick}>
          <div>
            <div style={{ fontSize: 16 }}>
              {(this.state.bio || this.state.fadingIn) && !this.state.fadingOut
                ? 'close'
                : 'info'}
            </div>
          </div>
        </InfoBlock>
        <ScrollPositionContext.Consumer>
          {({ scrollPosition }) => (
            <ContentfulContext.Consumer>
              {({ items }) => (
                <div id="loop" style={{ padding: '0 12%' }}>
                  <ContainerWrapper>
                    <ScrollEntranceStyled transform="translate3d(0, 400px, 0)">
                      {items &&
                        items
                          .concat(
                            !isMobile && items.length % 2 > 0 ? items : []
                          )
                          .map((item, index) => (
                            <VideoItem
                              key={item.sys.id + index}
                              scrollPosition={scrollPosition}
                              index={index}
                              video={item}
                              version={0}
                              debug={false}
                            />
                          ))}
                    </ScrollEntranceStyled>
                  </ContainerWrapper>
                  {isMobile ? (
                    <div style={{ height: 200 }} />
                  ) : (
                    <ContainerWrapper id="second">
                      <div>
                        {items &&
                          items
                            .concat(
                              !isMobile && items.length % 2 > 0 ? items : []
                            )
                            .map((item, index) => (
                              <VideoItem
                                clone
                                key={`${item.sys.id + index}2`}
                                scrollPosition={scrollPosition}
                                index={index}
                                video={item}
                                version={1}
                                debug={false}
                              />
                            ))}
                      </div>
                    </ContainerWrapper>
                  )}
                </div>
              )}
            </ContentfulContext.Consumer>
          )}
        </ScrollPositionContext.Consumer>
      </>
    )
  }
}
export default ItemsList
